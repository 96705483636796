<template>
  <button
    v-if="isButton"
    ref="button"
    :data-cy="dataCy"
    class="button-component"
    :class="buttonClass"
    :aria-disabled="disabled"
    v-bind="$attrs"
    @click.prevent="click"
    @keydown.enter.space.prevent="click"
  >
    <transition name="fade">
      <span v-if="loading" class="loading__wrapper">
        <span class="loading" />
      </span>
    </transition>
    <span class="button-label" :class="{ invisible: loading }">
      <slot />
    </span>
  </button>

  <a
    v-else
    ref="link"
    class="button-component link"
    :data-cy="dataCy"
    :aria-hidden="disabled ? true : undefined"
    :class="buttonClass"
    :href="href"
    :rel="rel"
    :target="target"
    v-bind="$attrs"
    @click="click"
  >
    <transition name="fade">
      <span v-if="loading" class="loading__wrapper">
        <span class="loading" />
      </span>
    </transition>
    <span class="button-label" :class="{ invisible: loading }">
      <slot />
    </span>
  </a>
</template>

<script>
export default {
  name: 'AccessibleButtonComponent',
  props: {
    buttonType: {
      validator(value) {
        return [
          'highlight',
          'primary',
          'secondary',
          'look-like-link',
          'look-disabled'
        ].includes(value)
      },
      type: String,
      default: 'primary'
    },
    disableFullWidth: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: undefined
    },
    target: {
      type: String,
      default: undefined
    },
    rel: {
      type: String,
      default: undefined
    },
    dataCy: {
      type: String,
      default: 'button'
    },
    clickhandler: {
      type: Function,
      default: undefined
    }
  },
  computed: {
    isButton() {
      return !this.href
    },
    isLookLikeLinkBtn() {
      return this.buttonType === 'look-like-link'
    },
    buttonClass() {
      const res = []

      if (this.buttonType) {
        res.push(`button-component__${this.buttonType}`)
      }

      if (this.disableFullWidth || this.isLookLikeLinkBtn) {
        res.push('disable-full-width')
      }

      if (this.loading) {
        res.push('not-clickable')
      }

      return res
    }
  },
  methods: {
    click(e) {
      if (!this.loading) {
        if (this.clickhandler) {
          e.preventDefault()
          this.clickhandler(e)
        }
      } else {
        e.preventDefault()
      }
    },
    focus() {
      if (this.isButton) {
        this.$refs.button.focus()
      } else {
        this.$refs.link.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading__wrapper {
  display: flex;
  height: 1px;
  width: 25px;
}

.loading {
  transition: opacity 2s 1s;
  opacity: 1;
  position: absolute;
  border: 3px solid transparent;
  border-left: 3px solid $root-color;
  border-top: 3px solid $root-color;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

.button-component {
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;

  &.not-clickable {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.button-component__look-like-link {
  display: inline;
  text-align: start;
  color: $button--look-like-link-color;

  &:focus {
    border-radius: 2px;
  }
}

.button-component__highlight {
  @include accessible-button-color-filled('highlight');
}

.button-component__primary {
  @include accessible-button-color-filled('primary');
}

.button-component__secondary {
  @include accessible-button-color-outlined('secondary');
}

.button-component__error {
  @include accessible-button-color-filled('error');
}

.button-component__look-disabled {
  @include button-color-filled('look-disabled');
}

.button-component__secondary,
.button-component__highlight,
.button-component__primary,
.button-component__error,
.button-component__look-disabled {
  display: flex;

  @include font-normal;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  max-width: 100%;
  min-height: 44px;
  border-radius: 8px;
  padding-top: 3px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  text-transform: none;
  user-select: none;
  touch-action: manipulation;

  &:focus {
    border-radius: 8px;
  }

  @include breakpoint($from-tablet) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include breakpoint($up-to-tablet) {
    &:not(.disable-full-width) {
      width: 100%;
    }
  }

  .button-label {
    min-width: 88px;
  }
}

.invisible {
  transition: opacity 0.5s;
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
